import React from 'react'

import Layout from '../../components/Layout/Layout'
import BlogRoll from '../../components/BlogRoll/BlogRoll'

const Blog = ({ location }) => {
  return (
    <Layout title="Blog" location={location}>
      <div className="container content content-body">
        <div className="page-content">
          <BlogRoll />
        </div>
      </div>
    </Layout>
  )
}

export default Blog;