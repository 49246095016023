import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const BlogCard = ({ post }) => {
  return (
      <Link to={post.fields.slug} className="post-card">
        <header className="post-card-header">
          {post.frontmatter.featuredpost && <span>Featured</span>}
          <h2 className="post-card-title">{post.frontmatter.title}</h2>
          <p style={{marginBottom:"10px", fontStyle:"italic", fontSize:"0.8em"}}>{post.frontmatter.date}</p>
        </header>
      <section className="post-card-excerpt">{post.excerpt}</section>
    </Link>
  )
}

BlogCard.propTypes = {
    post: PropTypes.shape({
        fields: PropTypes.shape({
          slug: PropTypes.string.isRequired,
        }),
    }).isRequired,
}

export default BlogCard
